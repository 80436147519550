// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
global.$ = require('jquery')
window.jQuery = $;
window.$ = $;

import Glide from '@glidejs/glide'


document.addEventListener("DOMContentLoaded", function() {

    $('.form_with_ok_modal').on("successed", function (e) {
        $(this).parent().find("#modal_form_successed").modal("show");
    });

    var mainCarousel = new Glide('.glide_carousel', {
        type: 'carousel',
        perView: 1,
        autoplay: 5000
    })

    var refList = new Glide('.glide_ref_list', {
        type: 'carousel',
        perView: 1,
        autoplay: 5000
    })

    mainCarousel.mount()
    refList.mount()
});
